var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-tabs',[_c('b-tab',{attrs:{"active":""}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-0",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.guardar)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Empleado","label-size":"sm"}},[_c('b-form-input',{attrs:{"trim":"","size":"sm","disabled":"","value":_vm.empleado}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Fecha Antiguedad Anterior","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Fecha de Antiguedad Anterior","label-for":"txtFechaAntiguedadAnterior"}},[_c('b-form-datepicker',{attrs:{"disabled":"","state":_vm.getValidationState(
                                                        validationContext
                                                    ),"placeholder":"Seleccione una fecha","dark":"","date-format-options":{
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                },"reset-button":"","label-reset-button":"Limpiar","label-prev-month":"mes anterior","label-next-month":"mes siguiente","label-prev-year":"a�o anterior","label-next-year":"a�o siguiente","label-current-month":"mes actual","label-no-date-selected":"seleccione una fecha","label-help":""},model:{value:(
                                                    _vm.fechaAntiguedadAnterior
                                                ),callback:function ($$v) {
                                                    _vm.fechaAntiguedadAnterior
                                                =$$v},expression:"\n                                                    fechaAntiguedadAnterior\n                                                "}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext .errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Fecha Antiguedad Nueva","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Fecha de Antiguedad Nueva","label-for":"txtFechaAntiguedadNueva"}},[_c('b-form-datepicker',{attrs:{"state":_vm.getValidationState(
                                                        validationContext
                                                    ),"placeholder":"Seleccione una fecha","dark":"","date-format-options":{
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                },"reset-button":"","label-reset-button":"Limpiar","label-prev-month":"mes anterior","label-next-month":"mes siguiente","label-prev-year":"a�o anterior","label-next-year":"a�o siguiente","label-current-month":"mes actual","label-no-date-selected":"seleccione una fecha","label-help":""},model:{value:(
                                                    _vm.fechaAntiguedadNueva
                                                ),callback:function ($$v) {
                                                    _vm.fechaAntiguedadNueva
                                                =$$v},expression:"\n                                                    fechaAntiguedadNueva\n                                                "}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext .errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex float-right mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"guardar"}},[_vm._v(" Guardar ")]),_c('b-button',{attrs:{"variant":"secondary","type":"button"},on:{"click":_vm.cancelar}},[_vm._v(" Cancelar ")])],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }